import { Spinner, Center } from '@chakra-ui/react'

const LoadingPage = () => {
    return (
        <Center h='100vh' w='100vw'>
            <Spinner size='xl'/>
        </Center>
    )
}

export default LoadingPage;