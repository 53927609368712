import { useState } from 'react';
import { Flex, 
    VStack, 
    FormControl, 
    InputGroup, 
    InputLeftElement, 
    Input, 
    Spacer, 
    Button, 
    FormErrorMessage, 
    Modal, 
    useDisclosure,
    Text,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Image,
    ModalCloseButton} from '@chakra-ui/react';
import { FaRegEnvelope } from 'react-icons/fa';
import '../../styles/login.css'; 
import LoginNavbar from './LoginNavbar';
import { validateEmail } from '../../utils/Utility';
import '../../styles/background.css'
import LoadingPage from '../Loader';
import useImagePreloader from '../../utils/ImagePreloader';


const loginClick = (email) => {
    let loginUri = `/api/tm/v1/account/login/${email}?app=dnaspaces&supportlogin=true`;
    window.location.href = loginUri;
};

const MainLogin =() => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [email, setEmail] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [emailError, setEmailError] = useState(false);

    const imageSrc = process.env.PUBLIC_URL + '/cisco_spaces_logo.svg';
    const loaded = useImagePreloader(imageSrc);

    const handleLogin = (e) => {
        e.preventDefault();
        if (!email) {
            setEmailErrorMessage("Email is required.");
            setEmailError(true);
        } else if (!validateEmail(email)) {
            setEmailErrorMessage("Incorrect email.");
            setEmailError(true);
        } else {
            setEmailError(false);
            loginClick(email, false, false);
        }
    };

    if(!loaded){
        return (
            <LoadingPage/>
        )
    }
    return (
        <div className="background-svg">
            <LoginNavbar/>
            {/* <Flex width="100vw" justifyContent="center" alignItems="center" height="500px" className='login-welcome-flex'>
                <Text className='login-welcome-text'>Mapjobs, Managed Precisely</Text>
            </Flex> */}
            <Flex className="login-container">
                <Button
                borderRadius={5}
                type="submit"
                variant="solid"
                bg="#0eb8ec"
                color="white"
                _hover={{ bg: "skyblue" }}
                onClick={onOpen}
                size={'lg'}
                width={'140px'}
                height={'50px'}>
                    <Text className='login-button-text'>Login</Text>
                </Button>
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={'3xl'}>
                <ModalOverlay />
                <ModalContent height={'650px'} p={10}>
                    <ModalHeader
                    alignContent={'center'}
                    pt={10}
                    pb={20}>
                    <Flex justifyContent="center" width="100%">
                        <Image
                        width={'180px'}
                        objectFit='contain'
                        src={process.env.PUBLIC_URL + '/cisco_spaces_logo.svg'}
                        alt='Cisco DNASpaces Logo'/>
                    </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction="column" align="center" width="100%">
                            <Flex justifyContent={'flex-start'} width="80%" pb={'30px'}> 
                                <Text className='login-modal-header'>Login</Text>
                            </Flex>
                            <VStack
                            as="form"
                            spacing={4} // Changed from "gap" to "spacing" for consistency
                            width="80%" // Set your desired width here
                            alignItems="center" // Center the form controls
                            onSubmit={handleLogin} // Add the submit event handler here
                            >
                            <FormControl isInvalid={emailError}>
                                <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<FaRegEnvelope color="rgba(200,200,200,1)" />}
                                />
                                <Input
                                    type="email"
                                    placeholder="Email ID"
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                />
                                </InputGroup>
                                {emailError && <FormErrorMessage>{emailErrorMessage}</FormErrorMessage>}
                            </FormControl>
                            <Spacer />
                            <Button
                                borderRadius={5}
                                type="submit"
                                variant="solid"
                                colorScheme="blue"
                                width="full"
                                height={'50px'}
                            >
                                <Text className='login-modal-continue'>Continue</Text>
                            </Button>
                            </VStack>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
      );
}

export default MainLogin;