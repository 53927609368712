import { useState, useEffect } from 'react';

const useImagePreloader = (imageSrc) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => setLoaded(true);
        img.onerror = () => setLoaded(false);
    }, [imageSrc]);

    return loaded;
};

export default useImagePreloader;