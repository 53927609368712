import {
    Box,
    Flex,
    useColorModeValue,
    Heading,
    Text,
    Image,
    Divider
} from '@chakra-ui/react';
import '../../styles/navbar.css'

export default function LoginNavbar() {
    return (
        <Flex 
        bg={'transparent'} 
        justifyContent='left' 
        alignItems={'center'}
        pt={8}
        pb={5}
        pl={20}
        pr={5}
        gap={4}>
            <Image
            width={'200px'}
            objectFit='contain'
            src={process.env.PUBLIC_URL + '/cisco_dnaspaces_logo.svg'}
            alt='Cisco DNASpaces Logo'/>
            <Text className='title-brand-login'>Intelligent Monitoring Dashboard</Text>
        </Flex>
    );
}