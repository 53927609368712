import { AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Text, HStack, Link, Button, IconButton } from "@chakra-ui/react";
import Table from './Table';
import { WarningIcon, CheckCircleIcon, InfoIcon} from '@chakra-ui/icons';
import '../../styles/App.css';

const CustomAccordionItem = ({app, code, values, links, columnValue, conditionalRowStyles, resolvedCount, unresolvedCount, maxTimestamp, newRecords}) => {
    const blink = newRecords.has(`${app}-${code}`);
    return (
        <AccordionItem 
            key={`${app}-${code}`}
            className={blink? 'alert':''}>
            <h2>
                <AccordionButton>
                    <HStack  as="span" flex='1' textAlign='left' paddingTop={'1vh'} paddingBottom={'1vh'} gap={'5vw'}>
                        <Text width={'6vw'}>{app}</Text>
                        <Text width={'6vw'}>{code}</Text>
                        <Link href={links[app][code][0].runbook} isExternal>
                            <Text 
                                width={'10vw'} noOfLines={1} 
                                as={(links[app][code][0].runbook==null)?'i':'u'}
                                color={(links[app][code][0].runbook==null)?'grey':'blue'}>
                                {(links[app][code][0].runbook==null)?"Not Available":links[app][code][0].runbook}
                            </Text>
                        </Link>
                        <Link href={links[app][code][0].selfheal} isExternal>
                            <Text 
                            width={'10vw'} noOfLines={1} 
                            as={(links[app][code][0].selfheal==null)?'i':'u'}
                            color={(links[app][code][0].selfheal==null)?'grey':'blue'}>
                                {(links[app][code][0].selfheal==null)?"Not Available":links[app][code][0].selfheal}
                            </Text>
                        </Link>
                        <Text width={'14vw'}>{new Date(maxTimestamp).toLocaleString()}</Text>
                    </HStack>
                    <HStack>
                        <HStack 
                        backgroundColor={'lightgreen'} 
                        padding={1}
                        borderRadius={5}
                        >
                            <Text>{resolvedCount}</Text>
                            <CheckCircleIcon color={'darkgreen'}/>
                        </HStack>
                        <HStack
                        backgroundColor={'lightpink'} 
                        padding={1}
                        borderRadius={5}
                        >
                            <Text>{unresolvedCount}</Text>
                            <WarningIcon color={'red'}/>
                        </HStack>
                        </HStack>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel>
                <Table
                    key={`${app}_${code}`}
                    columns={columnValue}
                    data={values}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </AccordionPanel>
        </AccordionItem>
    );
}

export default CustomAccordionItem;