import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { accordionTheme } from './styles/Style.js';

const theme = extendTheme({
  components: {
    Accordion: accordionTheme,
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>
); 