import { 
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button, 
    CircularProgress,
    HStack,
    Text,
    Flex} from "@chakra-ui/react";
import React from "react";

export default function AlertBox({alertMessage, alertTopic, acceptAlertOption, declineAlertOption, isOpen, onClose, onAccept, alertLoading}){
    const cancelRef = React.useRef()
    return(
        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={cancelRef}
            isCentered
        >
            <AlertDialogOverlay>
                {
                    alertLoading && 
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {alertTopic}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Flex justifyContent={'center'}>
                                <CircularProgress isIndeterminate color='blue.300'/>
                            </Flex>
                        </AlertDialogBody>
                    </AlertDialogContent>
                }
                {
                    !alertLoading &&
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {alertTopic}
                        </AlertDialogHeader>
                        <AlertDialogCloseButton/>

                        <AlertDialogBody>
                            {alertMessage}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            {declineAlertOption}
                        </Button>
                        <Button colorScheme='blue' onClick={onAccept} ml={3}>
                            {acceptAlertOption}
                        </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                }
            </AlertDialogOverlay>
        </AlertDialog>
    )
}