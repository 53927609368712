import DataTable from "react-data-table-component";

const Table = ({columns, data, conditionalRowStyles}) => {
    return (
        <DataTable
            columns={columns}
            data={data}
            defaultSortFieldId="timestamp"
            highlightOnHover
            conditionalRowStyles={conditionalRowStyles}
            pagination
        />
    );
}

export default Table;