import { Accordion, Checkbox, Box, Text, HStack , IconButton, Flex, Heading, CircularProgress, useDisclosure} from "@chakra-ui/react";
import { RepeatIcon, InfoIcon } from "@chakra-ui/icons";
import { useEffect, useState, useRef } from "react";
import { getGroupedData, timestampSort } from "../../utils/Utility";
import CustomAccordionItem from "./CustomAccordion";
import { fetchData } from '../../utils/Utility';
import AlertInfoModal from "./AlertInfo";

export default function Data({data, setData, lastUpdated, setLastUpdated, query, timeRange, selectedRows, setSelectedRows, liveMode, isLoading, setIsLoading}){
    
    const [groupedData, setGroupedData] = useState({}); // State to group the results
    const [links, setLinks] = useState({});  // State to set the links mapping to the app:errorcode
    const [updateTime, setUpdateTime] = useState("");
    const [infoRowData, setInfoRowData] = useState(null);
    const {onOpen, isOpen, onClose} = useDisclosure();

    //--------------------Define data columns and their styles and actions----------------------------------------------------------------------------
    const columnValue = [ 
        {id: "alertid",     name: "AlertId", selector: row => row.alertId, sortable: false, width: '15vw'},
        {id: "timestamp",   name: "Timestamp", selector:  row => row.timestamp, sortable: true , sortFunction: timestampSort, width: '15vw'},
        {id: "host",        name: "Host", selector:  row => row.host, sortable: true},
        {id: "level",       name: "Level", selector: row => row.level, sortable: false},
        {
            id: "info",
            name: "Alert Info",
            cell: row => (
                    <IconButton 
                        icon={<InfoIcon color="black"/>} 
                        borderRadius={60} 
                        size={'sm'} 
                        variant='outline'
                        onClick={(e) => handleViewInfo(e, row.id)}/>
                )
        },
        {id: "alerted",     name: "Alerted", selector: row => row.alerted, sortable: false},
        {
            id: "resolved",
            name: "Select to Resolve",
            cell: row => (
                !row.resolved && (
                    <Checkbox
                        borderColor={'black'}
                        onChange={(e) => handleCheckboxChange(e, row.id)}
                        isChecked={selectedRows.includes(row.id)}
                    />
                )
            )
        }
    ];
    const conditionalRowStyles = [
        {
            when: row => row.resolved,
            style: {
                backgroundColor: 'rgba(212, 237, 218, 0.3)',
            },
        },
        {
            when: row => !row.resolved,
            style: {
                backgroundColor: 'rgba(248, 215, 218, 0.3)',
            },
        }
    ]
    const handleViewInfo = (e, rowId) => {
        e.stopPropagation();
        const rowData = data.find(row => row.id === rowId);
        console.log(rowData);
        setInfoRowData(rowData);
        onOpen();
    }
    const handleCheckboxChange = (e, rowId) => {
        if (e.target.checked) {
            setSelectedRows(prev => [...prev, rowId]);
        } else {
            setSelectedRows(prev => prev.filter(selectedRowId => selectedRowId !== rowId));
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------------------------
    
    //------Group the data-----------------------------------------------------------------------------------------------------------------------------
            //----------------New record blink animation-----------------------------------------------------------------------------------------------
    const prevGroupedDataRef = useRef();
    const [newRecords, setNewRecords] = useState(new Set());
    useEffect(() => {
        prevGroupedDataRef.current = groupedData;
    }, [groupedData]);

    useEffect(() => {
        if(liveMode) {
            const timeout = setTimeout(() => {
                setNewRecords(new Set());
            }, 5000); // Adjust delay to match blink animation duration
      
            return () => clearTimeout(timeout); // Clean up on unmount
        }
    }, [newRecords, liveMode]);

    useEffect(()=>{
        const {groupedData, links} = getGroupedData(data);
        setLinks(links);
        if (liveMode) {
            const newItems = [];
            Object.keys(groupedData).forEach(app => {
                Object.keys(groupedData[app]).forEach(code => {
                    (groupedData[app][code] || []).forEach(record =>{
                        const prevRecords = prevGroupedDataRef.current[app]?.[code] || [];
                        if(!prevRecords.some(prevRecord => prevRecord.alertId === record.alertId && prevRecord.alerted === record.alerted && prevRecord.host === record.host && prevRecord.id === record.id && prevRecord.level === record.level && prevRecord.originalTimestamp === record.originalTimestamp)){
                            newItems.push(`${app}-${code}`);
                        }
                    });
                });
            });
            setNewRecords(new Set(newItems));
        }
        setGroupedData(groupedData);
    }, [data]);
    //--------------------------------------------------------------------------------------------------------------------------------------------------

    //----------------Set last updated time and Refresh action------------------------------------------------------------------------------------------
    useEffect(()=>{
        const date = new Date(lastUpdated);
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        const dateString = date.toLocaleString('en-US', options);
        setUpdateTime(dateString);
    }, [lastUpdated]);
    
    const handleRefresh = ()=>{
        const timestamp = new Date().getTime();
        fetchData(query, timeRange, setData, setIsLoading);
        setLastUpdated(timestamp);
    }
    //-----------------------------------------------------------------------------------------------------------------------------------------------------
    
    return(
        <div>
            <Box position='relative' paddingTop={'1vh'} paddingBottom={'1vh'} borderStyle={'solid'} borderColor={'black'} borderWidth={'1px'} paddingLeft={'1vw'}>
                <HStack justifyContent="space-between">
                    <HStack gap={'5vw'}>
                        <Text width={'6vw'} fontSize={'small'}>App Name</Text>
                        <Text width={'6vw'} fontSize={'small'}>Error Code</Text>
                        <Text width={'10vw'} fontSize={'small'}>Runbook</Text>
                        <Text width={'10vw'} fontSize={'small'}>Selfheal</Text>
                        <Text width={'11vw'} fontSize={'small'}>Latest Record Timestamp</Text>
                    </HStack>
                    <HStack alignItems={'center'} paddingRight={5}>
                        <Text fontSize={'xs'} maxWidth={'14vw'}>Last Updated : {updateTime}</Text>
                        <IconButton
                            colorScheme='green'
                            variant='solid' 
                            size={'xs'}             
                            isRound={true}
                            onClick={handleRefresh}
                            icon={<RepeatIcon/>}/>
                    </HStack>
                </HStack>
            </Box>
            {
                isLoading && 
                <Flex justifyContent={'center'} p={10}>
                    <CircularProgress isIndeterminate color='blue.300'/>
                </Flex>
            }
            {
                !isLoading &&
                    <Accordion 
                    allowToggle
                    paddingBottom={'5vw'}>
                    { data.length == 0? 
                        <Flex justifyContent='center'>
                            <Box padding={5}><Heading size={'md'}>No Data Available</Heading></Box>
                        </Flex>
                        
                        :

                        Object.entries(groupedData).flatMap(([app, codes]) =>
                                Object.entries(codes).map(([code, values]) => ({ app, code, values }))
                            )
                            .sort((a, b) => {
                                const maxTimestampA = Math.max(...a.values.map(({ originalTimestamp }) => originalTimestamp));
                                const maxTimestampB = Math.max(...b.values.map(({ originalTimestamp }) => originalTimestamp));
                                return maxTimestampB - maxTimestampA;
                            })
                            .map(({ app, code, values }, index) => {
                                const resolvedCount = values.filter(({ resolved }) => resolved).length;
                                const unresolvedCount = values.length - resolvedCount;
                                const maxTimestamp = Math.max(...values.map(({ originalTimestamp }) => originalTimestamp));
                                return(
                                    <CustomAccordionItem
                                        key={`${app}-${code}`}
                                        app={app}
                                        code={code}
                                        values={values}
                                        links={links}
                                        columnValue={columnValue}
                                        conditionalRowStyles={conditionalRowStyles}
                                        resolvedCount={resolvedCount}
                                        unresolvedCount={unresolvedCount}
                                        maxTimestamp={maxTimestamp}
                                        newRecords={newRecords}
                                    />
                                );
                            })
                        }
                    </Accordion>
            }
            <AlertInfoModal
                isOpen={isOpen}
                onClose={onClose}
                rowData={infoRowData}/>
        </div>
    )
}