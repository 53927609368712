import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    VStack,
    HStack,
    Text,
    Box,
    Spacer,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
 
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default function AlertInfoModal({isOpen, onClose, rowData}){
    if(rowData === null)return
    console.log(rowData);
    const alertId = rowData.record.alertId
    const logger = rowData.record.logger
    const message = rowData.record.message
    const stacktrace = rowData.record.stacktrace
    return(
        <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        isCentered
        size={'5xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {alertId}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box p={5}>
                        <VStack spacing={4}>
                            <HStack
                                w="full"
                                justify="center"
                                align="center"
                                p={5}>
                                <Text fontWeight={'bold'} minW="100px">Logger:</Text>
                                <Box 
                                border="1px solid black"
                                p={3}
                                overflowX={'auto'}
                                width={"100%"}>
                                    <Text whiteSpace={"nowrap"}>{logger}</Text>
                                </Box>
                            </HStack>

                            <HStack
                                w="full"
                                justify="center"
                                align="center"
                                pl={5}
                                pr={3}
                                pt={3}
                                pb={3}>
                                <Text fontWeight={'bold'} minW="100px">Message:</Text>
                                <Box flex={1} width={"500px"}>
                                    <Accordion allowToggle w="100%">
                                        <AccordionItem>
                                            <h3>
                                                <AccordionButton>
                                                    <Box flex='1' textAlign='left' maxW={'97%'}>
                                                        <Text noOfLines={1}>{message}</Text>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h3>
                                            <AccordionPanel pb={4}>
                                                <Box>
                                                    <Text>{message}</Text>
                                                </Box>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            </HStack>

                            {/* Below snippet is self splitting the lines 
                            <HStack
                                w="full"
                                justify="center"
                                align="start"
                                pl={5}
                                pr={3}
                                pt={3}
                                pb={3}>
                                <Text fontWeight={'bold'} minW="100px"  pt={2}>Stacktrace:</Text>
                                <Box flex={1} width={"500px"}>
                                    <Accordion allowToggle w="100%">
                                        <AccordionItem>
                                            <h3>
                                                <AccordionButton>
                                                    <Box flex='1' textAlign='left' maxW={'97%'}>
                                                        <Text noOfLines={1}>{stacktrace}</Text>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h3>
                                            <AccordionPanel pb={4}>
                                                <Box overflowX="auto">
                                                    {stacktrace.split("\n").map((line, index) => (
                                                    <Text key={index} whiteSpace="nowrap">{line}</Text>
                                                    ))}
                                                </Box>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            </HStack> 
                            */}

                            <HStack
                                w="full"
                                justify="center"
                                align="start"
                                pl={5}
                                pr={3}
                                pt={3}
                                pb={3}>
                                <Text fontWeight={'bold'} minW="100px"  pt={2}>Stacktrace:</Text>
                                <Box flex={1} width={"500px"}>
                                    <Accordion allowToggle w="100%">
                                        <AccordionItem>
                                            <h3>
                                                <AccordionButton>
                                                    <Box flex='1' textAlign='left' maxW={'97%'}>
                                                        <Text noOfLines={1}>{stacktrace}</Text>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h3>
                                            <AccordionPanel pb={4}>
                                                <Box overflowX="auto">
                                                    <SyntaxHighlighter language="java" style={docco}>
                                                        {stacktrace}
                                                    </SyntaxHighlighter>
                                                </Box>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            </HStack>
                        </VStack>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}